<template>
  <div class="peopleReactionList">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      ref="list"
      :finishedText="finishedText"
    >
      <div class="list" v-for="(item, index) in listData" :key="index">
        <div class="list-t">
          <div class="date">{{ item.insertTime }}</div>
          <div :class="['status', status + item.status]">
            {{ oStatusMap[item.status] }}
          </div>
        </div>
        <div class="list-b">
          <div class="list-l">
            <img :src="item.picture1" alt="" />
          </div>
          <div class="list-r">
            <div class="des">{{ item.description }}</div>
            <div class="name">{{ item.reactionUser }}</div>
            <div class="age">{{ item.age }}</div>
            <div class="address">{{ item.detailedAddress }}</div>
          </div>
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { getListByUserIdUrl } from "./api";
import { oStatusMap } from "./map";
export default {
  name: "peopleReactionList",
  components: {},
  props: {},
  data() {
    return {
      oStatusMap,
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
  },
  created() {},
  mounted() {},
  methods: {
    findFeedbackPage() {
      let params = {};
      params = {
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
      };
      this.$axios
        .get(`${getListByUserIdUrl}`, { params: params })
        .then((res) => {
          console.log(res, 333);
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            if (this.requestData.curPage == 1) {
              this.listData = res.data.records;
            } else {
              const list = this.listData.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.listData.push(item);
              });
            }
            this.listData.forEach((ele) => {
              if (ele.picture) {
                ele.picture1 =
                  ele.picture.split(",") && ele.picture.split(",")[0];
              }
              if (ele.picture1) {
                ele.picture1 = this.$handleImg(200, 200, ele.picture1);
              }
            });
          } else {
          }
          this.requestData.curPage++;
        });
    },
    onLoad() {
      this.findFeedbackPage();
    },
  },
};
</script>

<style scoped lang="less">
.peopleReactionList {
  width: 100%;
  min-height: 100vh;
  background: #f9f9f9;
  box-sizing: border-box;
  .list {
    padding: 20px 30px;
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 20px;
    .list-t {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 30px;
    }
    .list-b {
      margin-top: 20px;
      display: flex;
      align-items: center;
      .list-l {
        width: 200px;
        height: 200px;
        flex-shrink: 0;
        border-radius: 10px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      .list-r {
        flex: 1;
        .des {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.8);
          line-height: 28px;
        }
        .name {
          margin: 10px 0;
        }
        .name,
        .age,
        .address {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.8);
          line-height: 28px;
        }
        .name,
        .age {
          color: rgba(0, 0, 0, 0.5);
          margin: 10px 0;
        }
      }
    }
  }
}
</style>
