import { mapHelper } from "@/utils/utils.js";

// 	审核状态
const oStatus = [
  {
    value: 1,
    text: "已完成",
  },
  {
    value: 2,
    text: "无法处理",
  },
  {
    value: 0,
    text: "待处理",
  },
];
const { map: oStatusMap, setOps: oStatusOps } = mapHelper.setMap(oStatus);
export { oStatus, oStatusMap, oStatusOps };
