import { render, staticRenderFns } from "./peopleReactionList.vue?vue&type=template&id=3bd0adc2&scoped=true"
import script from "./peopleReactionList.vue?vue&type=script&lang=js"
export * from "./peopleReactionList.vue?vue&type=script&lang=js"
import style0 from "./peopleReactionList.vue?vue&type=style&index=0&id=3bd0adc2&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bd0adc2",
  null
  
)

export default component.exports